var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"3de44a0e64149188bfe7e50f1865160fb2a0ab35"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs'
import determineSentryIsEnabled from 'shared/helpers/determineSentryIsEnabled'
import configureSentryBreadcrumb from 'shared/helpers/configureSentryBreadcrumb'

/**
 * This file configures the initialization of Sentry on the browser.
 *
 * The config you add here will be used whenever a page is visited.
 * https://docs.sentry.io/platforms/javascript/guides/nextjs/
 */

Sentry.init({
  enabled: determineSentryIsEnabled(),
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1.0,
  /**
   * Note: if you want to override the automatic release value, do not set a
   * `release` value here - use the environment variable `SENTRY_RELEASE`, so
   * that it will also get attached to your source maps
   */

  beforeBreadcrumb(breadcrumb, hint) {
    return configureSentryBreadcrumb(breadcrumb, hint)
  }
})
